import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import FormControl from "@mui/material/FormControl";
import Divider from "@mui/material/Divider";
import InnerPageheading from "../../components/InnerPageheading";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CustomizedSnackbars from "../../components/Toast";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import loginImage from "../../assets/images/LoginImage.png";
import CircularProgress from "@mui/material/CircularProgress";

const primaryColor = getComputedStyle(document.documentElement)
  .getPropertyValue("--ty-primary-color")
  .trim();

const theme = createTheme({
  typography: {
    fontFamily: "var(--ty-font-family)",
  },
  palette: {
    primary: {
      main: primaryColor,
    },
  },
});

const Login = ({ setIsLoggedIn }) => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const [checkPassword, setCheckPassword] = React.useState(false);
  const [checkUsername, setCheckUsername] = React.useState(false);
  const [checkToken, setcheckToken] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState({ text: "", severity: "" });

  const [showToast, setShowToast] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [auth, setAuth] = React.useState({ usernameOrEmail: "", password: "" });
  const [loginLoader, setLoginLoader] = React.useState(false);

  // const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleClickShowPassword = () => {
    const input = document.getElementById("standard-password");
    const cursorPosition = input.selectionStart; // Get current cursor position
    setShowPassword((show) => !show);
    setTimeout(() => {
      input.setSelectionRange(cursorPosition, cursorPosition); // Restore cursor position
    }, 0);
  };

  const handleAuthChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setAuth({
      ...auth,
      [name]: value,
    });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoginLoader(true);
    await axios
      .post(process.env.REACT_APP_API_BASE_URL + "api/auth/login", auth)
      .then((response) => {
        if (response.data.success) {
          localStorage.setItem("accessToken", response.data.data.accessToken);
          localStorage.setItem("user_id", response.data.data.id);
          localStorage.setItem("club_id", response.data.data.club_id);
          localStorage.setItem(
            "refreshtoken",
            response.data.data.refreshToken.token
          );
          localStorage.setItem("time_zone", response.data.data.timeZone);
          setOpen(true);
          setSnackbar({ text: "Login Successful", severity: "success" });

          const currentTime = new Date().getTime();
          localStorage.setItem("lastRequestTime", currentTime);

          navigate("/");
          window.location.reload();
        } else {
          setOpen(true);
          setSnackbar({
            text: "Incorrect username or password",
            severity: "error",
          });
          setIsLoggedIn(false);
          setLoginLoader(false);
        }
      })
      .catch((e) => {
        setOpen(true);
        setSnackbar({
          text: "Incorrect username or password",
          severity: "error",
        });
        setLoginLoader(false);
      });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <ThemeProvider theme={theme}>
      <CustomizedSnackbars
        severity={snackbar.severity}
        open={open}
        setShowToast={setOpen}
        text={snackbar.text}
      />
      <form onSubmit={handleLogin}>
        <Box
          className="ty_application_design"
          sx={{
            backgroundImage: "none",
          }}
        >
          <Box className="authentication_screens">
            <Box
              sx={{
                height: "100%",
              }}
            >
              <video autoPlay loop height="100%" muted poster={loginImage}>
                <source
                  src="https://topyacht.s3.us-east-2.amazonaws.com/TopYacht-Login-Video.mp4"
                  type="video/mp4"
                  autoplay="autoplay"
                  muted=""
                  width="100%"
                  height="100vh"
                  webkit-playsinline=""
                  playsinline=""
                  loop=""
                />
              </video>
            </Box>
            <Box className="main_wrapper">
              <Grid container justifyContent="center" alignItems="center">
                <Grid item lg={8} md={7} sm={12} xs={12}>
                  <Box className="loginPageLeft">
                    <img
                      className="animationLoginPage"
                      src="/assets/images/top_YatchLogo.svg"
                      title="Top Yacht"
                      alt="Top Yacht"
                    />

                    <Box>
                      <Divider className="dividerAnimation" />
                    </Box>
                    <Box className="animationLoginPage">
                      <Typography variant="p">
                        <p>
                          The world’s most comprehensive software suite for
                          sailing results management
                        </p>
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item lg={4} md={5} sm={12} xs={12}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid
                      container
                      spacing={0}
                      sx={{
                        justifyContent: "space-between",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <InnerPageheading headerName="Login" />
                    </Grid>
                  </Box>
                  <Box className="loginBox">
                    {/* <Stack sx={{ width: "100%" }} spacing={2}>
                    <Alert
                      icon={false}
                      className="alertBox"
                      severity="success"
                      onClose={() => {}}
                    >
                      We have sent a password reset link to your registered
                      email address. Please click the link to complete the reset
                      process.
                    </Alert>
                    </Stack> */}
                    <Box className="loginFields">
                      <FormLabel
                        className={
                          checkUsername
                            ? "ty-uppercase-label errorLabelColor"
                            : "ty-uppercase-label"
                        }
                        sx={{
                          display: "block",
                          width: "100%",
                          marginBottom: "10px",
                        }}
                      >
                        Username{" "}
                      </FormLabel>
                      <FormControl fullWidth>
                        <Input
                          value={auth.usernameOrEmail}
                          onChange={handleAuthChange}
                          name="usernameOrEmail"
                          size="small"
                          placeholder="Enter username"
                          sx={{
                            padding: "8.5px 14px",
                            "&::after": {
                              display: "none",
                            },
                            "&::before": {
                              display: "none",
                            },
                          }}
                          id="standard-username"
                        />
                      </FormControl>
                      {checkUsername && (
                        <Typography variant="caption" color="error">
                          Email cannot be empty
                        </Typography>
                      )}
                    </Box>
                    <Box className="loginFields">
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <FormLabel
                          className={
                            checkPassword
                              ? "ty-uppercase-label errorLabelColor"
                              : "ty-uppercase-label"
                          }
                          sx={{
                            display: "block",
                            width: "100%",
                            marginBottom: "0 !important",
                          }}
                        >
                          Password{" "}
                        </FormLabel>
                      </Box>
                      <FormControl fullWidth>
                        <Input
                          value={auth.password}
                          onChange={handleAuthChange}
                          name="password"
                          size="small"
                          placeholder="Enter password"
                          sx={{
                            padding: "8.5px 14px",
                            "&::after": {
                              display: "none",
                            },
                            "&::before": {
                              display: "none",
                            },
                          }}
                          id="standard-password"
                          type={showPassword ? "text" : "password"}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                sx={{
                                  color: "#356DAD",
                                }}
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                      {checkPassword && (
                        <Typography variant="caption" color="error">
                          Password cannot be empty
                        </Typography>
                      )}
                      <Typography
                        variant="p"
                        sx={{
                          // width: "100%",
                          // textAlign: "right",
                          mt: 1,
                          display: "flex",
                          justifyContent: "flex-end",
                          fontWeight: "600",
                          fontSize: "14px !important",
                        }}
                      >
                        <Link to="forgot-password">Forgot Password</Link>
                      </Typography>
                    </Box>
                    <Box className="loginBoxBottom">
                      <FormGroup
                        aria-label="position"
                        row
                        sx={{
                          my: 0,
                          gap: 1.5,
                        }}
                      >
                        <FormControlLabel
                          value="end"
                          control={
                            <Checkbox
                              sx={{
                                color: "#A0A0A0",
                                padding: "0px",
                                backgroundColor: "#fff",
                                height: "16px",
                                width: "16px",
                                borderRadius: "0",
                                margin: "9px",
                                // "&.Mui-checked": {
                                //   color: "red",
                                // },
                              }}
                            />
                          }
                          label="Remember Me"
                          labelPlacement="end"
                        />
                      </FormGroup>
                      <Button
                        className="ty_primary_btn loginButton"
                        disabled={loginLoader}
                        type="submit"
                      >
                        <Box
                          sx={{
                            display: "flex",
                            gap: "10px",
                            alignItems: "center",
                          }}
                        >
                          {loginLoader && (
                            <CircularProgress
                              sx={{ color: "#fff" }}
                              size={14}
                            />
                          )}
                          Login <ArrowForwardIosIcon className="loginIcon" />
                        </Box>
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </form>
    </ThemeProvider>
  );
};

export default Login;
