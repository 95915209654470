export const SESSION_EXPIRED = 'SESSION_EXPIRED';
export const FETCH_USER_DATA = 'FETCH_USER_DATA';
export const FETCH_MENU_DATA = 'FETCH_MENU_DATA';
export const FETCH_CLUB_VISIBILITY_TYPE = 'FETCH_CLUB_VISIBILITY_TYPE';
export const FETCH_EVENT_VISIBILITY_TYPE = 'FETCH_EVENT_VISIBILITY_TYPE';

//Club
export const FETCH_CLUB_DATA = 'FETCH_CLUB_DATA';
export const FETCH_CLUB_LIST = 'FETCH_CLUB_LIST';
export const UPDATE_CLUB_DATA = 'UPDATE_CLUB_DATA';
export const UPDATE_CLUB_CLASS_DATA = 'UPDATE_CLUB_CLASS_DATA';
export const STANDARD_CLUB_CLASSES = 'STANDARD_CLUB_CLASSES';
export const UPDATE_CLUB_ASSOCIATION_DATA = 'UPDATE_CLUB_ASSOCIATION_DATA';
//HcDef
export const FETCH_HCDEFS_DATA = 'FETCH_HCDEFS_DATA';
export const FETCH_HCDEFS_LIST = 'FETCH_HCDEFS_LIST';
export const UPDATE_HCDEFS_DATA = 'UPDATE_HCDEFS_DATA';
//Score Defs
export const FETCH_SCORE_POINT_DEF_DATA = 'FETCH_SCORE_POINT_DEF_DATA';
export const FETCH_STANDARD_SCORE_POINT_DEF_DATA = 'FETCH_STANDARD_SCORE_POINT_DEF_DATA';
export const UPDATE_SCORE_POINT_DEF_DATA = 'UPDATE_SCORE_POINT_DEF_DATA';
//Penalty
export const FETCH_PENALTY_LIST = 'FETCH_PENALTY_LIST';
export const FETCH_SCORE_PENALITY_DEF_DATA = 'FETCH_SCORE_PENALITY_DEF_DATA';
export const UPDATE_SCORE_PENALITY_DEF_DATA = 'UPDATE_SCORE_PENALITY_DEF_DATA';
export const FETCH_ALL_PENALTIES = 'FETCH_ALL_PENALTIES';

//Events
export const CREATE_EVENT_DATA = 'CREATE_EVENT_DATA';
export const FETCH_EVENT_LIST = 'FETCH_EVENT_LIST';
export const UPDATE_EVENT_DATA = 'UPDATE_EVENT_DATA';
export const SELECTED_EVENT_DATA = 'SELECTED_EVENT_DATA';
export const SELECTED_EVENT_KB_DATA = 'SELECTED_EVENT_KB_DATA';
export const SELECTED_EVENT_OTB_DATA = 'SELECTED_EVENT_OTB_DATA';
export const FETCH_EVENT_KB_LIST = 'FETCH_EVENT_KB_LIST';
export const FETCH_EVENT_OTB_LIST = 'FETCH_EVENT_OTB_LIST';

//Series-KB
export const SELECTED_SERIES_DATA = 'SELECTED_SERIES_DATA';
export const CREATE_SERIES_FLEET_CLASSES = 'CREATE_SERIES_FLEET_CLASSES';
export const CREATE_SERIES_RESULT_OPTIONS = 'CREATE_SERIES_RESULT_OPTIONS';
export const CREATE_SERIES_RESULT_OPTION_ALLOCATIONS = 'CREATE_SERIES_RESULT_OPTION_ALLOCATIONS';
export const CREATE_SERIES_SCORE_OPTION = 'CREATE_SERIES_SCORE_OPTION';
export const CREATE_UPDATE_SERIES_DATA = 'CREATE_UPDATE_SERIES_DATA';
export const FETCH_SERIES_KB_DATA = 'FETCH_SERIES_KB_DATA';
export const EMPTY_SERIES_KB_DATA = 'EMPTY_SERIES_KB_DATA';
export const FETCH_SERIES_LIST = 'FETCH_SERIES_LIST';
export const EDIT_SERIES_DATA = 'EDIT_SERIES_DATA';
export const FETCH_INCOMPLETE_SERIES_DATA = 'FETCH_INCOMPLETE_SERIES_DATA';
export const DELETE_INCOMPLETE_SERIES_DATA = 'DELETE_INCOMPLETE_SERIES_DATA';
export const FETCH_SERIES_NAME_LIST = 'FETCH_SERIES_NAME_LIST';
export const FETCH_SERIES_KB_NAME_LIST = 'FETCH_SERIES_KB_NAME_LIST';
export const FETCH_SERIES_OTB_NAME_LIST = 'FETCH_SERIES_OTB_NAME_LIST';


//Series-OTB
export const CREATE_SERIES_OTB_FLEET_CLASSES = 'CREATE_SERIES_OTB_FLEET_CLASSES';
export const CREATE_SERIES_OTB_RESULT_OPTIONS = 'CREATE_SERIES_OTB_RESULT_OPTIONS';
export const CREATE_SERIES_OTB_RESULT_OPTION_ALLOCATIONS = 'CREATE_SERIES_OTB_RESULT_OPTION_ALLOCATIONS';
export const CREATE_SERIES_OTB_SCORE_OPTION = 'CREATE_SERIES_OTB_SCORE_OPTION';
export const CREATE_UPDATE_SERIES_OTB_DATA = 'CREATE_UPDATE_SERIES_OTB_DATA';
export const FETCH_SERIES_OTB_DATA = 'FETCH_SERIES_OTB_DATA';
export const EMPTY_SERIES_OTB_DATA = 'EMPTY_SERIES_OTB_DATA';
export const FETCH_SERIES_OTB_LIST = 'FETCH_SERIES_OTB_LIST';
export const EDIT_SERIES_OTB_DATA = 'EDIT_SERIES_OTB_DATA';

export const FETCH_SERIES_DATA = 'FETCH_SERIES_DATA';
export const EMPTY_SERIES_DATA = 'EMPTY_SERIES_DATA';


export const FETCH_TES_EVENTS = 'FETCH_TES_EVENTS';
export const FETCH_TES_SERIES = 'FETCH_TES_SERIES';
export const FETCH_TES_ASSO_EVENTS = 'FETCH_TES_ASSO_EVENTS';
export const FETCH_TES_ASSO_SERIES = 'FETCH_TES_ASSO_SERIES';

export const FETCH_LOGOUT = 'FETCH_LOGOUT';
export const FETCH_REFRESH_TOKEN = 'FETCH_REFRESH_TOKEN';

export const CREATE_UPDATE_RACE_DAYS = 'CREATE_UPDATE_RACE_DAYS';
export const FETCH_RACE_DAYS = 'FETCH_RACE_DAYS';
export const FETCH_RACE_DAYS_SERIES = 'FETCH_RACE_DAYS_SERIES';

//Race Session
export const FETCH_SERIES_OF_RACE_DATE = 'FETCH_SERIES_OF_RACE_DATE';
export const UPDATE_SERIES_OF_RACE_DATE = 'UPDATE_SERIES_OF_RACE_DATE';
export const FETCH_RACE_SESSION = 'FETCH_RACE_SESSION';
export const FETCH_RACE_SESSION_BY_ID = 'FETCH_RACE_SESSION_BY_ID';
export const UPDATED_RACE_SESSION = 'UPDATED_RACE_SESSION';
export const CREATE_UPDATE_RACE_SESSION = 'CREATE_UPDATE_RACE_SESSION';
export const MOVE_RACE_SESSION = 'MOVE_RACE_SESSION';
export const FETCH_RACE_SESSION_BY_SERIES = 'FETCH_RACE_SESSION_BY_SERIES';
export const FETCH_RACE_SESSION_BY_EVENT = 'FETCH_RACE_SESSION_BY_EVENT';
export const FETCH_RACE_SESSION_BY_SERIES_ONLY = 'FETCH_RACE_SESSION_BY_SERIES_ONLY';
export const CLONE_LAST_RACE_DAY = 'CLONE_LAST_RACE_DAY'; //Regatta
export const CLONE_LAST_RACE_DAY_NORMAL_RACE = 'CLONE_LAST_RACE_DAY_NORMAL_RACE';
export const RACE_NO_TRACKER = 'RACE_NO_TRACKER';
export const DELETE_RACE_SESSION_DETAIL = 'DELETE_RACE_SESSION_DETAIL';
export const UPDATE_RACE_SESSION_DETAIL = 'UPDATE_RACE_SESSION_DETAIL';
export const ABANDON_RACE = 'ABANDON_RACE';
export const FETCH_RACE_SESSION_NAMES_BY_SERIES = 'FETCH_RACE_SESSION_NAMES_BY_SERIES';


//Entrants
export const CREATE_UPDATE_RACE_ENTRANTS = 'CREATE_UPDATE_RACE_ENTRANTS';
export const FETCH_RACE_ENTRANTS = 'FETCH_RACE_ENTRANTS';
export const EMPTY_RACE_ENTRANTS = 'EMPTY_RACE_ENTRANTS'
export const FETCH_SERIES_RACE_ENTRANTS = 'FETCH_SERIES_RACE_ENTRANTS';
export const STORE_TES_ENTRANTS = 'STORE_TES_ENTRANTS';
export const FETCH_TES_ENTRANTS = 'FETCH_TES_ENTRANTS';
export const DELETE_ALL_ENTRANTS = 'DELETE_ALL_ENTRANTS';
export const DELETE_ENTRANT = 'DELETE_ENTRANT';

export const FETCH_OFFSETS = 'FETCH_OFFSETS';

export const FETCH_ENTRANTS_EVENT_LIST = 'FETCH_ENTRANTS_EVENT_LIST';
export const FETCH_ENTRANTS_SERIES_LIST = 'FETCH_ENTRANTS_SERIES_LIST';
export const FETCH_ENTRANTS_SERIES_DATA = 'FETCH_ENTRANTS_SERIES_DATA';


export const TRIGGER_CSV_IMPORT = 'TRIGGER_CSV_IMPORT';
export const TRIGGER_TES_IMPORT = 'TRIGGER_TES_IMPORT';
export const ENTRANT_IMPORT_SOURCE = 'ENTRANT_IMPORT_SOURCE';

export const FETCH_SIGNED_ON = 'FETCH_SIGNED_ON';
export const FETCH_PREVIOUS_RACE_SERIES = 'FETCH_PREVIOUS_RACE_SERIES';
export const FETCH_ENTRANT_OFFSETS = 'FETCH_ENTRANT_OFFSETS';

//Results
export const FETCH_SERIES_BY_RACE_SESSION = 'FETCH_SERIES_BY_RACE_SESSION';
export const FETCH_RESULTS_BY_SESSION = 'FETCH_RESULTS_BY_SESSION';
export const FETCH_SERIES_SCORES = 'FETCH_SERIES_SCORES';
export const FETCH_PUBLISHED_RESULTS_BY_SESSION = 'FETCH_PUBLISHED_RESULTS_BY_SESSION';
export const FETCH_PUBLISHED_RESULTS_BY_SERIES = 'FETCH_PUBLISHED_RESULTS_BY_SERIES';
export const ADD_PUBLISHED_RESULTS_BY_SESSION = 'ADD_PUBLISHED_RESULTS_BY_SESSION';
export const ADD_PUBLISHED_RESULTS_BY_SERIES = 'ADD_PUBLISHED_RESULTS_BY_SERIES';

export const UPDATE_RESULTS = 'UPDATE_RESULTS';

export const ADD_HIDDEN_COLUMNS = 'ADD_HIDDEN_COLUMNS';
export const FETCH_HIDDEN_COLUMNS = 'FETCH_HIDDEN_COLUMNS';

//Race Overview
export const CHANGE_RACE_DATE = 'CHANGE_RACE_DATE';

//Timezone List
export const FETCH_TIME_ZONE_LIST = 'FETCH_TIME_ZONE_LIST';


//Handicap

// -- Handicap Graph
export const SELECTED_RESULT_OPTION1 = 'SELECTED_RESULT_OPTION1';
export const SELECTED_RESULT_OPTION2 = 'SELECTED_RESULT_OPTION2';
export const SELECTED_OPTIONS = 'SELECTED_OPTIONS';
export const FETCH_HANDICAP_BOAT_DATA = 'FETCH_HANDICAP_BOAT_DATA';
export const HANDICAP_BOAT_DATA_BY_FLEET = 'HANDICAP_BOAT_DATA_BY_FLEET';
export const SELECTED_BOAT = 'SELECTED_BOAT';
export const FETCH_HANDICAP_RACE_DATA1 = 'FETCH_HANDICAP_RACE_DATA1';
export const FETCH_HANDICAP_RACE_DATA2 = 'FETCH_HANDICAP_RACE_DATA2';

// -- Handicap Summary
export const FETCH_HANDICAP_SUMMARY = 'FETCH_HANDICAP_SUMMARY';
export const EMPTY_HANDICAP_SUMMARY = 'EMPTY_HANDICAP_SUMMARY';
export const FLEET_HANDICAP_SUMMARY = 'FLEET_HANDICAP_SUMMARY';

