import CryptoJS from "crypto-js";

export function arraysEqual(arr1, arr2) { 
    if (arr1 === arr2) return true;
    if (arr1 == null || arr2 == null) return false;
    if (arr1.length !== arr2.length) return false;
  
    // Sort both arrays
    const sortedArr1 = arr1.slice().sort();
    const sortedArr2 = arr2.slice().sort();
  
    // Compare sorted arrays
    for (let i = 0; i < sortedArr1.length; i++) {
      if (sortedArr1[i] !== sortedArr2[i]) return false;
    }
    return true;
  }
  
 export function arraysEqualElements(arr1, arr2) {
    arr2.forEach((x) => {
      if (!arr1.includes(x)) return false;
    });
    return true;
  }
  
export function isUUID(str) {
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
  return uuidRegex.test(str);
}

// Function to encrypt a string using AES encryption
export function encrypt(param) {
  console.log("param", param)
  const key = CryptoJS.enc.Latin1.parse('1234567812345678'); // 16-byte key
  const iv = CryptoJS.enc.Latin1.parse('1234567812345678');  // 16-byte IV

  // Encrypt the parameter
  const encrypted = CryptoJS.AES.encrypt(param, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.ZeroPadding
  });

  console.log('Encrypted:', encrypted.toString()); // Encrypted as Base64 string

  return encrypted.toString(); // Return as a Base64-encoded string
}

// Function to decrypt an encrypted string
export function decrypt(encrypted) {
  const key = CryptoJS.enc.Latin1.parse('1234567812345678'); // 16-byte key
  const iv = CryptoJS.enc.Latin1.parse('1234567812345678');  // 16-byte IV

  // Decrypt the parameter
  const decrypted = CryptoJS.AES.decrypt(encrypted, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.ZeroPadding
  });

  console.log('Decrypted:', decrypted.toString(CryptoJS.enc.Utf8)); // Decrypted text
  return decrypted.toString(CryptoJS.enc.Utf8); // Return as a UTF-8 string
}
