import * as actionTypes from "./actionsTypes";
import axios from "axios";
import axiosInstance from "../axios/axiosInstance";
import { encrypt, decrypt } from '../scenes/global/helperFunctions';

var club_id = localStorage.getItem("club_id");
var user_id = localStorage.getItem("user_id");


var encryptedClubId = encodeURIComponent(encrypt(localStorage.getItem("club_id")));
var encryptedUserId = encodeURIComponent(encrypt(localStorage.getItem("user_id")));

export const handleClubVisibility = (visibilityType) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_CLUB_VISIBILITY_TYPE,
      payload: visibilityType,
    });
  };
};
export const handleEventVisibility = (visibilityType) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_EVENT_VISIBILITY_TYPE,
      payload: visibilityType,
    });
  };
};

export const fetchClubList = () => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get("api/clubs/list");
      dispatch({
        type: actionTypes.FETCH_CLUB_LIST,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchClubData = () => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get("api/clubs?clubId=" + encryptedClubId);
      dispatch({
        type: actionTypes.FETCH_CLUB_DATA,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const updateClubData = (updatedClubData) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post("api/clubs", updatedClubData);
      dispatch({
        type: actionTypes.UPDATE_CLUB_DATA,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const updateClubClassData = (updatedClubClassData) => {
  const uuidRegex =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

  const updatedData = updatedClubClassData.map((obj) => {
    // Create a copy of the object to avoid mutating the original one
    const newObj = { ...obj };

    // Check if clubClassId is a string and matches the UUID pattern
    if (
      typeof newObj.clubClassId === "string" &&
      uuidRegex.test(newObj.clubClassId)
    ) {
      newObj.clubClassId = null; // Set clubClassId to null
    }

    return newObj; // Return the new object
  });
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/clubs/club_classes?clubId="+encryptedClubId,
        updatedData
      );
      dispatch({
        type: actionTypes.UPDATE_CLUB_CLASS_DATA,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const fetchStandardClubClasses = () => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/club_classes/toBeAdded?clubId="+encryptedClubId
      );
      dispatch({
        type: actionTypes.STANDARD_CLUB_CLASSES,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const updateClubAssociationData = (updatedClubAssociationData) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/clubs/club_associations?clubId="+encryptedClubId,
        updatedClubAssociationData
      );
      dispatch({
        type: actionTypes.UPDATE_CLUB_ASSOCIATION_DATA,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const fetchHcdefsData = (handicapdata) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_HCDEFS_DATA,
      payload: { data: handicapdata },
    });
  };
};

export const updateHcdefsData = (updatedHcdefsData, clone = false) => {
  let handicapDataPayload = { ...updatedHcdefsData };

  if (clone && updatedHcdefsData.cloneOption) {
    handicapDataPayload.handicapId = null;
  }
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/clubs/handicap?clubId="+encryptedClubId,
        handicapDataPayload
      );
      dispatch({
        type: actionTypes.UPDATE_HCDEFS_DATA,
        payload: response.data,
      });
      return { status: 1, message: response.data.data };
    } catch (error) {
      console.log(error);
      return { status: 0, message: error.response.data.message };
    }
  };
};

export const fetchHcdefsList = (showHidden = 1) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/handicap/list?clubId="+encryptedClubId+"&showHidden=" + showHidden
      );
      dispatch({
        type: actionTypes.FETCH_HCDEFS_LIST,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchMenuData = () => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get("api/privilages?userId=" + encryptedUserId);
      dispatch({
        type: actionTypes.FETCH_MENU_DATA,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchScorePointDefList = (isStandard) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/scores?clubId="+encryptedClubId
      );
      dispatch({
        type: actionTypes.FETCH_SCORE_POINT_DEF_DATA,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchStandardScorePointDefList = () => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/scores/standards?clubId="+encryptedClubId
      );
      dispatch({
        type: actionTypes.FETCH_STANDARD_SCORE_POINT_DEF_DATA,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const updateScorePointDefList = (updatedScrorePointDef) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/clubs/scores?clubId="+encryptedClubId,
        updatedScrorePointDef
      );
      dispatch({
        type: actionTypes.UPDATE_SCORE_POINT_DEF_DATA,
        payload: response.data,
      });
      return { status: 1, data: response.data };
    } catch (error) {
      return { status: 0, data: error.response.data };
    }
  };
};

//Events
export const createEventData = (eventData, eventId = null) => {
  return async (dispatch) => {
    try {
      // Create a shallow copy of eventData to modify for the API request
      const eventDataCopy = { ...eventData };
      if (eventId) {
        eventDataCopy.eventId = null;
        eventDataCopy.cloneOption = eventId;
      }
      const response = await axiosInstance.post(
        "api/clubs/events?clubId="+encryptedClubId,
        eventDataCopy
      );
      dispatch({
        type: actionTypes.CREATE_EVENT_DATA,
        payload: response.data,
      });
      return { status: 1, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: 0, data: error.response.data };
    }
  };
};

export const fetchEventList = (type = 0) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/events/list?clubId="+encryptedClubId+"&type=" + type
      );
      if (type == 1) {
        dispatch({
          type: actionTypes.FETCH_EVENT_KB_LIST,
          payload: response.data,
        });
      } else if (type == 2) {
        dispatch({
          type: actionTypes.FETCH_EVENT_OTB_LIST,
          payload: response.data,
        });
      } else {
        dispatch({
          type: actionTypes.FETCH_EVENT_LIST,
          payload: response.data,
        });
      }
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const updateEventData = (eventData) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.put(
        "api/clubs/events?clubId="+encryptedClubId,
        eventData
      );
      dispatch({
        type: actionTypes.UPDATE_EVENT_DATA,
        payload: eventData,
      });
      return { status: 1, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: 0, data: error.response.data };
    }
  };
};

export const selectedEventData = (eventData, type = 0) => {
  return async (dispatch) => {
    if (type == 1) {
      dispatch({
        type: actionTypes.SELECTED_EVENT_KB_DATA,
        payload: { data: eventData },
      });
    } else if (type == 2) {
      dispatch({
        type: actionTypes.SELECTED_EVENT_OTB_DATA,
        payload: { data: eventData },
      });
    } else {
      dispatch({
        type: actionTypes.SELECTED_EVENT_DATA,
        payload: { data: eventData },
      });
    }
  };
};

//Series-KB
export const selectedSeriesData = (seriesData) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.SELECTED_SERIES_DATA,
      payload: { data: seriesData },
    });
  };
};

export const fetchSeriesList = (eventId, showHidden = 1) => {
  const encryptedEventId = encodeURIComponent(encrypt(JSON.stringify(eventId)));

  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/series/list?eventId=" + encryptedEventId + "&showHidden=" +
          showHidden
      );
      dispatch({
        type: actionTypes.FETCH_SERIES_LIST,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const fetchSeriesNameList = (eventId, type = 0) => {
  const encryptedEventId = encodeURIComponent(encrypt(JSON.stringify(eventId)));

  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/series/listSeriesNames?clubId="+ encryptedClubId + "&eventId=" + encryptedEventId
      );
      if (type == 1) {
        dispatch({
          type: actionTypes.FETCH_SERIES_KB_NAME_LIST,
          payload: response.data,
        });
      } else if (type == 2) {
        dispatch({
          type: actionTypes.FETCH_SERIES_OTB_NAME_LIST,
          payload: response.data,
        });
      } else {
        dispatch({
          type: actionTypes.FETCH_SERIES_NAME_LIST,
          payload: response.data,
        });
      }
    } catch (error) {
      return error.response.data.message;
    }
  };
};
export const emptySeriesNameList = (type = 0) => {
  return (dispatch) => {
    if (type == 1) {
      dispatch({
        type: "EMPTY_KB_NAME_LIST",
        payload: { data: [] },
      });
    } else if (type == 2) {
      dispatch({
        type: "EMPTY_OTB_NAME_LIST",
        payload: { data: [] },
      });
    } else {
      dispatch({
        type: "EMPTY_NAME_LIST",
        payload: { data: [] },
      });
    }
  };
};
export const fetchSeriesData = (seriesId, type = 0) => {
  const encryptedSeries = encodeURIComponent(encrypt(JSON.stringify(seriesId)));

  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/series?clubId=" + encryptedClubId + "&seriesId=" +  encryptedSeries
      );
      if (type == 1) {
        dispatch({
          type: actionTypes.FETCH_SERIES_KB_DATA,
          payload: response.data,
        });
        return response.data;
      } else if (type == 2) {
        dispatch({
          type: actionTypes.FETCH_SERIES_OTB_DATA,
          payload: response.data,
        });
        return response.data;
      } else {
        dispatch({
          type: actionTypes.FETCH_SERIES_DATA,
          payload: response.data,
        });
        return response.data;
      }
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const emptySeriesData = (type = 0) => {
  return (dispatch) => {
      if (type == 1) {
        dispatch({
          type: actionTypes.EMPTY_SERIES_KB_DATA,
          payload: {data: {}},
        });
        return {data: {}};
      } else if (type == 2) {
        dispatch({
          type: actionTypes.EMPTY_SERIES_OTB_DATA,
          payload: {data: {}},
        });
        return {data: {}};
      } else {
        dispatch({
          type: actionTypes.EMPTY_SERIES_DATA,
          payload: {data: {}},
        });
        return {data: {}};
      }
  };
};

export const fetchIncompleteSeriesData = (eventId, cloneOption) => {
  const encryptedEvent = encodeURIComponent(encrypt(JSON.stringify(eventId)));

  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/series/incomplete?eventId=" + encryptedEvent + "&cloneOption=" + cloneOption
      );
      dispatch({
        type: actionTypes.FETCH_INCOMPLETE_SERIES_DATA,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const deleteIncompleteSeriesData = (seriesId) => {
  const encryptedSeries = encodeURIComponent(encrypt(JSON.stringify(seriesId)));

  return async (dispatch) => {
    try {
      const response = await axiosInstance.delete(
       "api/clubs/series?clubId=" + encryptedClubId + "&seriesId=" +  encryptedSeries
      );
      dispatch({
        type: actionTypes.DELETE_INCOMPLETE_SERIES_DATA,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};
export const createOrUpdateSeriesData = (seriesData, cloneOption = 0) => {
  let seriesDataPayload = { ...seriesData };
  if (cloneOption) {
    seriesDataPayload.cloneOption = seriesData.seriesId;
    seriesDataPayload.seriesId = null;
  }
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/clubs/series?clubId=" + encryptedClubId,
        seriesDataPayload
      );
      dispatch({
        type: actionTypes.CREATE_UPDATE_SERIES_DATA,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data };
    }
  };
};

export const editSeriesData = (seriesData) => {

  const encryptedSeries = encodeURIComponent(encrypt(JSON.stringify(seriesData.seriesId)));

  return async (dispatch) => {
    try {
      const response = await axiosInstance.put(
        "api/clubs/series?clubId=" +encryptedClubId+ "&seriesId=" + encryptedSeries,
        seriesData
      );
      dispatch({
        type: actionTypes.EDIT_SERIES_DATA,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data };
    }
  };
};

export const createFleetClasses = (
  seriesData,
  seriesId,
  cloneOption = false
) => {
  if (cloneOption) {
    seriesData.forEach((x) => (x.seriesFleetClassId = null));
  }
  const encryptedSeries = encodeURIComponent(encrypt(JSON.stringify(seriesId)));

  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/clubs/series/fleetClass?clubId=" + encryptedClubId + "&seriesId=" +encryptedSeries,
        seriesData
      );
      dispatch({
        type: actionTypes.CREATE_SERIES_FLEET_CLASSES,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data };
    }
  };
};

export const createResultOptions = (
  seriesData,
  seriesId,
  cloneOption = false
) => {
  if (cloneOption) {
    seriesData.forEach((x) => (x.seriesResultOptionId = null));
  }
  const encryptedSeries = encodeURIComponent(encrypt(JSON.stringify(seriesId)));
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/clubs/series/resultOption?clubId=" + encryptedClubId + "&seriesId=" + encryptedSeries,
        seriesData
      );
      dispatch({
        type: actionTypes.CREATE_SERIES_RESULT_OPTIONS,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data };
    }
  };
};

export const createResultOptionAllocations = (
  seriesData,
  seriesId,
  cloneOption = false
) => {
  if (cloneOption) {
    seriesData.forEach((x) => (x.seriesResultOptionAllocationId = null));
  }
  const encryptedSeries = encodeURIComponent(encrypt(JSON.stringify(seriesId)));

  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/clubs/series/resultOptionAllocation?clubId=" + encryptedClubId + "&seriesId=" + encryptedSeries,
        seriesData
      );
      dispatch({
        type: actionTypes.CREATE_SERIES_RESULT_OPTION_ALLOCATIONS,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data };
    }
  };
};
export const createScoreOption = (
  seriesData,
  seriesId,
  cloneOption = false
) => {
  if (cloneOption) {
    seriesData.seriesScoringOptionId = null;
  }
  const encryptedSeries = encodeURIComponent(encrypt(JSON.stringify(seriesId)));

  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/clubs/series/scoringOption?clubId=" + encryptedClubId + "&seriesId=" + encryptedSeries,
        seriesData
      );
      dispatch({
        type: actionTypes.CREATE_SERIES_SCORE_OPTION,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data };
    }
  };
};

//Series-OTB
export const fetchSeriesOTBList = (eventId, showHidden = 1) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/" +
          club_id +
          "/series/list/" +
          eventId +
          "?showHidden=" +
          showHidden
      );
      dispatch({
        type: actionTypes.FETCH_SERIES_OTB_LIST,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};
export const fetchSeriesOTBData = (seriesId) => {
  const encryptedSeriesId = encodeURIComponent(encrypt(JSON.stringify(seriesId)));
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/series?clubId=" + encryptedClubId + "&seriesId=" + encryptedSeriesId
      );
      dispatch({
        type: actionTypes.FETCH_SERIES_OTB_DATA,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};
export const createOrUpdateSeriesOTBData = (seriesData, cloneOption = 1) => {
  if (!cloneOption) {
    seriesData.cloneOption = seriesData.seriesId;
    seriesData.seriesId = null;
  }
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/clubs/series?clubId=" + encryptedClubId,
        seriesData
      );
      dispatch({
        type: actionTypes.CREATE_UPDATE_SERIES_OTB_DATA,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data };
    }
  };
};

export const editSeriesOTBData = (seriesData) => {
  const encryptedSeriesId = encodeURIComponent(encrypt(JSON.stringify(seriesData.seriesId)));

  return async (dispatch) => {
    try {
      const response = await axiosInstance.put(
        "api/clubs/series?clubId=" + encryptedClubId + "&seriesId=" + encryptedSeriesId,
        seriesData
      );
      dispatch({
        type: actionTypes.EDIT_SERIES_OTB_DATA,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data };
    }
  };
};

export const createFleetClassesOTB = (
  seriesData,
  seriesId,
  cloneOption = false
) => {
  if (cloneOption) {
    seriesData.forEach((x) => (x.seriesFleetClassId = null));
  }

  const encryptedSeriesId = encodeURIComponent(encrypt(JSON.stringify(seriesId)));

  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/clubs/series/fleetClass?clubId=" + encryptedClubId + "&seriesId=" + encryptedSeriesId,
        seriesData
      );
      dispatch({
        type: actionTypes.CREATE_SERIES_OTB_FLEET_CLASSES,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data };
    }
  };
};

export const createResultOptionsOTB = (
  seriesData,
  seriesId,
  cloneOption = false
) => {
  if (cloneOption) {
    seriesData.forEach((x) => (x.seriesResultOptionId = null));
  }

  const encryptedSeriesId = encodeURIComponent(encrypt(JSON.stringify(seriesId)));

  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/clubs/series/resultOption?clubId=" + encryptedClubId + "&seriesId=" + encryptedSeriesId,
        seriesData
      );
      dispatch({
        type: actionTypes.CREATE_SERIES_OTB_RESULT_OPTIONS,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data };
    }
  };
};

export const createResultOptionAllocationsOTB = (
  seriesData,
  seriesId,
  cloneOption = false
) => {
  if (cloneOption) {
    seriesData.forEach((x) => (x.seriesResultOptionAllocationId = null));
  }

  const encryptedSeriesId = encodeURIComponent(encrypt(JSON.stringify(seriesId)));

  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/clubs/series/resultOptionAllocation?clubId=" +
          encryptedClubId +
          "&seriesId=" +
          encryptedSeriesId,
        seriesData
      );
      dispatch({
        type: actionTypes.CREATE_SERIES_OTB_RESULT_OPTION_ALLOCATIONS,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data };
    }
  };
};
export const createScoreOptionOTB = (
  seriesData,
  seriesId,
  cloneOption = false
) => {
  if (cloneOption) {
    seriesData.seriesScoringOptionId = null;
  }

  const encryptedSeriesId = encodeURIComponent(encrypt(JSON.stringify(seriesId)));

  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/clubs/series/scoringOption?clubId=" + encryptedClubId + "&seriesId=" + encryptedSeriesId,
        seriesData
      );
      dispatch({
        type: actionTypes.CREATE_SERIES_OTB_SCORE_OPTION,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data };
    }
  };
};

//fetch penalty list
export const fetchScorePenaltyList = () => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/scorePenalties?clubId="+encryptedClubId
      );
      dispatch({
        type: actionTypes.FETCH_SCORE_PENALITY_DEF_DATA,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const SaveScorePenaltyList = (scorePenaltyListData) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/clubs/scorePenalties?clubId="+encryptedClubId,
        scorePenaltyListData
      );
      dispatch({
        type: actionTypes.UPDATE_SCORE_PENALITY_DEF_DATA,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data };
    }
  };
};

export const fetchPenaltyList = () => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/scorePenalties?clubId="+encryptedClubId
      );
      dispatch({
        type: actionTypes.FETCH_PENALTY_LIST,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

//EVENTS ENDS HERE

//FETCH TES DATA STARTS HERE
export const fetchTESEvents = (type = "keelboat") => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post("api/GetAllEvents/" + type, {
        clubIDs: [club_id],
      });
      dispatch({
        type: actionTypes.FETCH_TES_EVENTS,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};
export const fetchTESSeries = (eventIDs, type = "keelboat") => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/GetAllEvents/series/" + type,
        eventIDs
      );
      dispatch({
        type: actionTypes.FETCH_TES_SERIES,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const fetchTESAssoEvents = (clubIds, type = "keelboat") => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post("api/GetAllEvents/" + type, {
        clubIDs: clubIds,
      });
      dispatch({
        type: actionTypes.FETCH_TES_ASSO_EVENTS,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};
export const fetchTESAssoSeries = (eventIDs, type = "keelboat") => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/GetAllEvents/series/" + type,
        eventIDs
      );
      dispatch({
        type: actionTypes.FETCH_TES_ASSO_SERIES,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};
export const fetchlogout = (requestData) => {
  return async (dispatch) => {
    //{}
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_BASE_URL + "api/auth/logout",
        requestData
      );
      dispatch({
        type: actionTypes.FETCH_LOGOUT,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};
export const fetchrefreshToken = async () => {
  try {
    const requestData = {
      token: localStorage.getItem("refreshtoken"),
      userID: localStorage.getItem("user_id"),
    };
    const response = await axios.post(
      process.env.REACT_APP_API_BASE_URL + "api/auth/refreshToken",
      requestData
    );
    return { status: 1, data: response.data.accessToken };
  } catch (error) {
    console.log(error);
    return { status: 0, data: error.response.data.message };
  }
};

//Race Days
export const createOrUpdateRaceDays = (RaceData) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/clubs/RaceCalendar?clubId="+encryptedClubId,
        RaceData
      );
      dispatch({
        type: actionTypes.CREATE_UPDATE_RACE_DAYS,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data };
    }
  };
};
export const fetchRaceDays = (eventId, seriesId) => {
  const encryptedEventId = encodeURIComponent(encrypt(JSON.stringify(eventId)));
  const encryptedSeriesId = encodeURIComponent(encrypt(JSON.stringify(seriesId)));

  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/RaceCalendar?clubId="+encryptedClubId+ "&eventId="+encryptedEventId + "&seriesId=" +encryptedSeriesId
      );
      dispatch({
        type: actionTypes.FETCH_RACE_DAYS,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const fetchRaceDaysSeries = (eID) => {
  const encryptedEventId = encodeURIComponent(encrypt(JSON.stringify(eID)));

  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/RaceCalendar/event?clubId="+encryptedClubId + "&eventId="+encryptedEventId
      );
      dispatch({
        type: actionTypes.FETCH_RACE_DAYS_SERIES,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

//Race Session
export const fetchSeriesByRaceDate = (eventId, raceDate) => {
  const encryptedEventId = encodeURIComponent(encrypt(JSON.stringify(eventId)));

  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/RaceCalendar/series?clubId="+encryptedClubId + "&eventId="+encryptedEventId + "&raceDate=" + raceDate
      );
      dispatch({
        type: actionTypes.FETCH_SERIES_OF_RACE_DATE,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const updateSeriesOfRaceDate = (seriesData) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_SERIES_OF_RACE_DATE,
      payload: { data: seriesData },
    });
  };
};

export const fetchRaceSession = (eventId, sessionDate) => {
  const encryptedEvent = encodeURIComponent(encrypt(JSON.stringify(eventId)));
	
  return async (dispatch) => {
    dispatch({ type: "FETCH_RACE_SESSION_REQUEST" });

    try {
      const response = await axiosInstance.get(
        "api/clubs/RaceSession/getRaceSession/"+ sessionDate+"?eventId="+encryptedEvent
      );
      dispatch({
        type: actionTypes.FETCH_RACE_SESSION,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const fetchRaceSessionById = (raceSessionId) => {
	const encryptedSessionId = encodeURIComponent(encrypt(JSON.stringify(raceSessionId)));
	
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/RaceSession/raceSessionId?raceSessionId=" + encryptedSessionId
      );
      dispatch({
        type: actionTypes.FETCH_RACE_SESSION_BY_ID,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const fetchRaceSessionBySeries = (eventId, seriesId, sessionDate) => {
	const encryptedEventId = encodeURIComponent(encrypt(JSON.stringify(eventId)));
	const encryptedSeriesId = encodeURIComponent(encrypt(JSON.stringify(seriesId)));
	
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/RaceSession/getRaceSessionBySeries" +
          "/" +
          sessionDate+"?eventId="+encryptedEventId+"&seriesId="+encryptedSeriesId
      );
      dispatch({
        type: actionTypes.FETCH_RACE_SESSION_BY_SERIES,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const emptyRaceSession = () => {
  return (dispatch) => {
    dispatch({
      type: "EMPTY",
      payload: { data: [] },
    });
  };
};

export const fetchRaceSessionNamesBySeries = (
  eventId,
  seriesId,
  sessionDate
) => {
	const encryptedEventId = encodeURIComponent(encrypt(JSON.stringify(eventId)));
	const encryptedSeriesId = encodeURIComponent(encrypt(JSON.stringify(seriesId)));
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/RaceSession/list/"+sessionDate+"?eventId=" +
        encryptedEventId +
        "&seriesId=" +
        encryptedSeriesId 
      );
      dispatch({
        type: actionTypes.FETCH_RACE_SESSION_NAMES_BY_SERIES,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const fetchRaceSessionByEvent = (eventId) => {
	const encryptedEventId = encodeURIComponent(encrypt(JSON.stringify(eventId)));
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/RaceSession/events?eventId=" + encryptedEventId
      );
      dispatch({
        type: actionTypes.FETCH_RACE_SESSION_BY_EVENT,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const fetchRaceSessionBySeriesOnly = (seriesId) => {
  console.log("seriesId---", seriesId)
	const encryptedSeriesId = encodeURIComponent(encrypt(JSON.stringify(seriesId)));
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/RaceSession/getSessionBySeriesOnly?seriesId=" + encryptedSeriesId
      );
      dispatch({
        type: actionTypes.FETCH_RACE_SESSION_BY_SERIES_ONLY,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const updatedRaceSession = (raceSessionData) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATED_RACE_SESSION,
      payload: { data: raceSessionData },
    });
  };
};

export const createUpdateRaceSession = (raceSessionData) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/clubs/RaceSession",
        raceSessionData
      );
      dispatch({
        type: actionTypes.CREATE_UPDATE_RACE_SESSION,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data };
    }
  };
};

export const moveRaceSession = (
  eventId,
  raceSessionId,
  sourceDate,
  destDate
) => {
	const encryptedEventId = encodeURIComponent(encrypt(JSON.stringify(eventId)));
	const encryptedRaceSessionId = encodeURIComponent(encrypt(JSON.stringify(raceSessionId)));
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/RaceSession/move?eventId=" +
        encryptedEventId +
          "&raceSesionId=" +
          encryptedRaceSessionId +
          "&sourceSessionDate=" +
          sourceDate +
          "&destSessionDate=" +
          destDate
      );
      dispatch({
        type: actionTypes.MOVE_RACE_SESSION,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data };
    }
  };
};

export const changeRaceDate = (
  eventId,
  raceSessionDetailId,
  sourceDate,
  destDate
) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/RaceSession/Race" +
          eventId +
          "/" +
          raceSessionDetailId +
          "/" +
          sourceDate +
          "/" +
          destDate
      );
      dispatch({
        type: actionTypes.CHANGE_RACE_DATE,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data };
    }
  };
};

export const cloneLastRaceDay = (eventId, raceDate, initialRaceSession) => {
	const encryptedEventId = encodeURIComponent(encrypt(JSON.stringify(eventId)));
	
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/RaceSession/clone/" +
          raceDate+
          "?eventId="+encryptedEventId
      );
      const clonedRaceSess = response.data.data.map((x) => {
        return {
          ...x,
          cloneOption: x.raceSessionId,
          raceSessionId: "",
          sessionDate: raceDate,
          raceSessionsDetailList: x.raceSessionsDetailList.map((y) => {
            return {
              ...y,
              raceSessionDetailId: "",
              raceNo: y.raceNo + 1,
            };
          }),
        };
      });
      console.log(clonedRaceSess);
      // const updatedData = [...initialRaceSession, ...clonedRaceSess]
      let updatedRes = response.data;
      // updatedRes.data =[...updatedData]
      updatedRes.data = [...clonedRaceSess];

      dispatch({
        type: actionTypes.CLONE_LAST_RACE_DAY,
        payload: updatedRes,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data };
    }
  };
};
export const cloneLastRaceDayNormalRace = (
  eventId,
  normalRaceDay,
  raceDate
) => {
	const encryptedEventId = encodeURIComponent(encrypt(JSON.stringify(eventId)));
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/RaceSession/cloneNormalRace/" +
          normalRaceDay +
          "/" +
          raceDate+
          "?eventId="+encryptedEventId
      );
      const clonedRaceSess = response.data.data.map((x) => {
        return {
          ...x,
          cloneOption: x.raceSessionId,
          raceSessionId: "",
          sessionDate: raceDate,
          raceSessionsDetailList: x.raceSessionsDetailList.map((y) => {
            return {
              ...y,
              raceSessionDetailId: "",
              raceNo: y.raceNo + 1,
            };
          }),
        };
      });
      console.log(clonedRaceSess);
      // const updatedData = [...initialRaceSession, ...clonedRaceSess]
      let updatedRes = response.data;
      // updatedRes.data =[...updatedData]
      updatedRes.data = [...clonedRaceSess];

      dispatch({
        type: actionTypes.CLONE_LAST_RACE_DAY_NORMAL_RACE,
        payload: updatedRes,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data };
    }
  };
};

export const createUpdateRaceEntrants = (entrants) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/clubs/Entrants?clubId="+encryptedClubId,
        entrants
      );
      dispatch({
        type: actionTypes.CREATE_UPDATE_RACE_ENTRANTS,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data };
    }
  };
};

export const deleteEntrant = (entrantId) => {
	const encryptedEntrantId = encodeURIComponent(encrypt(JSON.stringify(entrantId)));

  return async (dispatch) => {
    try {
      const response = await axiosInstance.delete(
        "api/clubs/Entrants/delete?id=" + encryptedEntrantId
      );
      dispatch({
        type: actionTypes.DELETE_ENTRANT,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data };
    }
  };
};

export const fetchRaceEntrants = (seriesId, racesessionid) => {
	const encryptedSeriesId = encodeURIComponent(encrypt(JSON.stringify(seriesId)));
	const encryptedRacesessionId = encodeURIComponent(encrypt(JSON.stringify(racesessionid)));
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/Entrants/SessionEntrants?seriesId=" + encryptedSeriesId + "&raceSessionId=" + encryptedRacesessionId
      );
      dispatch({
        type: actionTypes.FETCH_RACE_ENTRANTS,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const emptyRaceEntrants = (seriesId, racesessionid) => {
  return  (dispatch) => {
    dispatch({
      type: actionTypes.EMPTY_RACE_ENTRANTS,
      payload: {data: []},
    });
  }
};

export const fetchSeriesEntrants = (seriesId) => {
	const encryptedSeriesId = encodeURIComponent(encrypt(JSON.stringify(seriesId)));
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/Entrants/SeriesEntrants?seriesId=" + encryptedSeriesId
      );
      dispatch({
        type: actionTypes.FETCH_SERIES_RACE_ENTRANTS,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const storeTESEntrants = (eventId, seriesId, TESEventId, TESSerisId) => {
	const encryptedEventId = encodeURIComponent(encrypt(JSON.stringify(eventId)));
	const encryptedSeriesId = encodeURIComponent(encrypt(JSON.stringify(seriesId)));
	const encryptedTESEventId = encodeURIComponent(encrypt(JSON.stringify(TESEventId)));
	const encryptedTESSeriesId = encodeURIComponent(encrypt(JSON.stringify(TESSerisId)));
	
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/TESEntrants/getTESEntrants?eventId=" +
        encryptedEventId +
          "&seriesId=" +
          encryptedSeriesId +
          "&clubId="+
          encryptedClubId+
          "&TESeventId=" +
          encryptedTESEventId +
          "&TESseriesId=" +
          encryptedTESSeriesId
      );
      dispatch({
        type: actionTypes.STORE_TES_ENTRANTS,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const fetchTESEntrants = (seriesId) => {
	const encryptedSeriesId = encodeURIComponent(encrypt(JSON.stringify(seriesId)));
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/TESEntrants/TESEntrantsBySeries?clubId="+encryptedClubId+"&seriesId=" + encryptedSeriesId
      );
      dispatch({
        type: actionTypes.FETCH_TES_ENTRANTS,
        payload: response.data,
      });
      return { status: true, data: response.data.data };
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const fetchEntrantsEvents = (type = 0) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/events?type=" + type
      );
      dispatch({
        type: actionTypes.FETCH_ENTRANTS_EVENT_LIST,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

//Series-KB
export const fetchEntrantsSeriesList = (eventId, showHidden = 1) => {
	const encryptedEventId = encodeURIComponent(encrypt(JSON.stringify(eventId)));
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/series/listSeriesNames?eventId=" +
        encryptedEventId +
          "&showHidden=" +
          showHidden
      );
      dispatch({
        type: actionTypes.FETCH_ENTRANTS_SERIES_LIST,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const fetchEntrantsSeriesData = (seriesId) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/series/" + seriesId
      );
      dispatch({
        type: actionTypes.FETCH_ENTRANTS_SERIES_DATA,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const triggerCSVImport = () => ({
  type: actionTypes.TRIGGER_CSV_IMPORT,
});

export const triggerTESImport = () => ({
  type: actionTypes.TRIGGER_TES_IMPORT,
});

export const entrantImportSource = (entrantImportSource) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ENTRANT_IMPORT_SOURCE,
      payload: entrantImportSource,
    });
  };
};
export const fetchSignedOn = (seriesId, date, type) => {
	const encryptedSeriesId = encodeURIComponent(encrypt(JSON.stringify(seriesId)));
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/TESSignOnEntrants/" +
          type +
          "/" +
          date+
          "?seriesId="+encryptedSeriesId
      );
      dispatch({
        type: actionTypes.FETCH_SIGNED_ON,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

//Results
export const fetchSeriesByRaceSession = (raceSessionId) => {
	const encryptedRaceSessionId = encodeURIComponent(encrypt(JSON.stringify(raceSessionId)));

  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/series/raceSession?raceSessionId=" + encryptedRaceSessionId
      );
      dispatch({
        type: actionTypes.FETCH_SERIES_BY_RACE_SESSION,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const emptySeriesList = () => {
  return (dispatch) => {
    dispatch({
      type: "EMPTY",
      payload: { data: [] },
    });
  };
};

export const fetchResultsBySession = (fleetId, raceSessionId) => {
	const encryptedFleetId = encodeURIComponent(encrypt(JSON.stringify(fleetId)));
	const encryptedRaceSessionId = encodeURIComponent(encrypt(JSON.stringify(raceSessionId)));

  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/results?fleetId=" + encryptedFleetId + "&raceSessionId=" + encryptedRaceSessionId
      );
      dispatch({
        type: actionTypes.FETCH_RESULTS_BY_SESSION,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const fetchSeriesScore = (seriesId, fleetId) => {
	const encryptedSeriesId = encodeURIComponent(encrypt(JSON.stringify(seriesId)));
	const encryptedFleetId = encodeURIComponent(encrypt(JSON.stringify(fleetId)));

  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/results/SeriesScores?clubId=" + 
          encryptedClubId +
          "&seriesId=" +
          encryptedSeriesId +
          "&fleetId=" +
          encryptedFleetId
      );
      dispatch({
        type: actionTypes.FETCH_SERIES_SCORES,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const fetchPreviousRaceSeries = (seriesId, raceSessionId) => {
	const encryptedSeriesId = encodeURIComponent(encrypt(JSON.stringify(seriesId)));
	const encryptedRaceSessionId = encodeURIComponent(encrypt(JSON.stringify(raceSessionId)));
	
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/Entrants/pre?seriesId=" +
        encryptedSeriesId +
          "&raceSessionId=" +
          encryptedRaceSessionId
      );
      dispatch({
        type: actionTypes.FETCH_PREVIOUS_RACE_SERIES,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const fetchOffsetEntrants = (entrants) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/clubs/EntrantsOffset",
        entrants
      );
      dispatch({
        type: actionTypes.FETCH_ENTRANT_OFFSETS,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const deleteRaceSessionDetail = (detailId, isDeleteParent = true) => {
	const encryptedDetailId = encodeURIComponent(encrypt(JSON.stringify(detailId)));
  return async (dispatch) => {
    try {
      const response = await axiosInstance.delete(
        "api/clubs/RaceSession/delete?sessionId=" +
        encryptedDetailId +
          "&isDeleteParent=" +
          isDeleteParent
      );
      dispatch({
        type: actionTypes.DELETE_RACE_SESSION_DETAIL,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data.message };
    }
  };
};

export const updateRaceSessionDetailStatus = (detailId, status) => {
	const encryptedDetailId = encodeURIComponent(encrypt(JSON.stringify(detailId)));
  return async (dispatch) => {
    try {
      const response = await axiosInstance.put(
        "api/clubs/RaceSession/update/"+status+"?detailId=" + encryptedDetailId
      );
      dispatch({
        type: actionTypes.UPDATE_RACE_SESSION_DETAIL,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data.message };
    }
  };
};

export const abandonRace = (detailId) => {
	const encryptedDetailId = encodeURIComponent(encrypt(JSON.stringify(detailId)));
  return async (dispatch) => {
    try {
      const response = await axiosInstance.put(
        "api/clubs/RaceSession/Abandon?detailId=" + encryptedDetailId
      );
      dispatch({
        type: actionTypes.ABANDON_RACE,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data.message };
    }
  };
};

export const fetchSavedOffsets = (seriesId, raceSessionId) => {
	
	const encryptedRaceSessionId= encodeURIComponent(encrypt(JSON.stringify(raceSessionId)));
	const encryptedSeriesId = encodeURIComponent(encrypt(JSON.stringify(seriesId)));
	
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/EntrantsOffset/getOffsets?seriesId=" +
        encryptedSeriesId +
          "&raceSessionId=" +
          encryptedRaceSessionId
      );
      dispatch({
        type: actionTypes.FETCH_OFFSETS,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const fetchPublishedResultsBySession = (fleetId, raceSessionId) => {
	const encryptedFleetId = encodeURIComponent(encrypt(JSON.stringify(fleetId)));
	const encryptedRaceSessionId = encodeURIComponent(encrypt(JSON.stringify(raceSessionId)));

  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/results/published?clubId=" +
          encryptedClubId +
          "&fleetId=" +
          encryptedFleetId +
          "&raceSessionId=" +
          encryptedRaceSessionId
      );
      dispatch({
        type: actionTypes.FETCH_PUBLISHED_RESULTS_BY_SESSION,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const insertPublishedResultsBySession = (results, seriesId, option) => {
	const encryptedSeriesId = encodeURIComponent(encrypt(JSON.stringify(seriesId)));
	const encryptedOption = encodeURIComponent(encrypt(JSON.stringify(option)));

  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/clubs/results/add?clubId=" + encryptedClubId + "&seriesId=" + encryptedSeriesId + "&option=" + encryptedOption,
        results
      );
      dispatch({
        type: actionTypes.ADD_PUBLISHED_RESULTS_BY_SESSION,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data };
    }
  };
};

export const fetchPublishedResultsBySeries = (eventId, seriesId, fleetId) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/" +
          club_id +
          "/publishSeriesScore/get/" +
          eventId +
          "/" +
          seriesId +
          "/" +
          fleetId
      );
      dispatch({
        type: actionTypes.FETCH_PUBLISHED_RESULTS_BY_SERIES,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const insertPublishedResultsBySeries = (
  results,
  eventId,
  seriesId,
  fleetId
) => {

	const encryptedEventId = encodeURIComponent(encrypt(JSON.stringify(eventId)));
	const encryptedSeriesId = encodeURIComponent(encrypt(JSON.stringify(seriesId)));
	const encryptedFleetId = encodeURIComponent(encrypt(JSON.stringify(fleetId)));

  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/clubs/publishSeriesScore/add?clubId=" +
          encryptedClubId +
          "&eventId=" +
          encryptedEventId +
          "&seriesId=" +
          encryptedSeriesId +
          "&fleetId=" +
          encryptedFleetId,
        results
      );
      dispatch({
        type: actionTypes.ADD_PUBLISHED_RESULTS_BY_SERIES,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data };
    }
  };
};

export const updateResults = (results, fleetId, raceSessionId) => {
	const encryptedFleetId = encodeURIComponent(encrypt(JSON.stringify(fleetId)));
	const encryptedRaceSessionId = encodeURIComponent(encrypt(JSON.stringify(raceSessionId)));

  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/clubs/results/update?clubId=" +
          encryptedClubId +
          "&fleetId=" +
          encryptedFleetId +
          "&raceSessionId=" +
          encryptedRaceSessionId,
        results
      );
      dispatch({
        type: actionTypes.UPDATE_RESULTS,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const fetchAllPenalties = () => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/scorePenalties/All?club_id=" + encryptedClubId
      );
      dispatch({
        type: actionTypes.FETCH_ALL_PENALTIES,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const deleteAllEntrants = (seriesId, sessionId) => {
	
	const encryptedSeriesId = encodeURIComponent(encrypt(JSON.stringify(seriesId)));
	const encryptedSessionId = encodeURIComponent(encrypt(JSON.stringify(sessionId)));
	
  return async (dispatch) => {
    try {
      const response = await axiosInstance.delete(
        "api/clubs/Entrants/deleteAll?seriesId=" + encryptedSeriesId + "&raceSessionId=" + encryptedSessionId
      );
      dispatch({
        type: actionTypes.DELETE_ALL_ENTRANTS,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data };
    }
  };
};

export const addHiddenColumns = (data) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/clubs/publishedResultColumn/add?clubId=" + encryptedClubId,
        data
      );
      dispatch({
        type: actionTypes.ADD_HIDDEN_COLUMNS,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const fetchHiddenColumns = (fleetId, raceSessionId) => {
  const encryptedFleetId = encodeURIComponent(encrypt(JSON.stringify(fleetId)))
  const encryptedRaceSessionId = encodeURIComponent(encrypt(JSON.stringify(raceSessionId)))

  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/publishedResultColumn/show?clubId=" +
        encryptedClubId +
        "&fleetId=" +
        encryptedFleetId +
        "&raceSessionId=" +
        encryptedRaceSessionId
      );
      dispatch({
        type: actionTypes.FETCH_HIDDEN_COLUMNS,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const fetchTimezoneList = () => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get("api/timezones");
      dispatch({
        type: actionTypes.FETCH_TIME_ZONE_LIST,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

//Handicap

// -- Handicap Graph

export const selectedResultOption1 = (resultOption) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SELECTED_RESULT_OPTION1,
      payload: { data: resultOption },
    });
  };
};
export const selectedResultOption2 = (resultOption) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SELECTED_RESULT_OPTION2,
      payload: { data: resultOption },
    });
  };
};

export const selectedOptions = (options) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SELECTED_OPTIONS,
      payload: { data: options },
    });
  };
};

export const selectedBoat = (boatData) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SELECTED_BOAT,
      payload: { data: boatData },
    });
  };
};

export const fetchHandicapBoatData = (seriesId, fleetId) => {
  console.log("seriesId", seriesId)
  console.log("fleetId", fleetId)

  const encryptedSeriesId = encodeURIComponent(encrypt(JSON.stringify(seriesId)))
  const encryptedFleetId = encodeURIComponent(encrypt(JSON.stringify(fleetId)))

  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/handicapData/boats?clubId="+encryptedClubId+"&seriesId="+encryptedSeriesId+"&fleetId="+encryptedFleetId
      );
      dispatch({
        type: actionTypes.FETCH_HANDICAP_BOAT_DATA,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const HandicapBoatDataByFleet = (selectedFleetData) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.HANDICAP_BOAT_DATA_BY_FLEET,
      payload: { data: selectedFleetData },
    });
  };
};

export const fetchHandicapRaceData1 = (fleetId, resultOpt, boatId) => {
  const encryptedBoatId = encodeURIComponent(encrypt(JSON.stringify(boatId)))
  const encryptedFleetId = encodeURIComponent(encrypt(JSON.stringify(fleetId)))

  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/handicapData/graphs?clubId="+encryptedClubId+ "&boatId="+encryptedBoatId+"&fleetId="+encryptedFleetId+"&resultOption="+resultOpt
      );
      dispatch({
        type: actionTypes.FETCH_HANDICAP_RACE_DATA1,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const fetchHandicapRaceData2 = (fleetId, resultOpt, boatId) => {
  const encryptedBoatId = encodeURIComponent(encrypt(JSON.stringify(boatId)))
  const encryptedFleetId = encodeURIComponent(encrypt(JSON.stringify(fleetId)))

  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/handicapData/graphs?clubId="+encryptedClubId+ "&boatId="+encryptedBoatId+"&fleetId="+encryptedFleetId+"&resultOption="+resultOpt
      );
      dispatch({
        type: actionTypes.FETCH_HANDICAP_RACE_DATA2,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

//Handicap Summary Tab

export const fetchHandicapSummary = (seriesId, fleetId) => {
  const encryptedSeriesId = encodeURIComponent(encrypt(JSON.stringify(seriesId)))
  const encryptedFleetId = encodeURIComponent(encrypt(JSON.stringify(fleetId)))

	  return async (dispatch) => {
	    try {
	      const response = await axiosInstance.get(
	        "api/clubs/handicapData/summary?clubId="+encryptedClubId+ "&seriesId="+ encryptedSeriesId+"&fleetId="+ encryptedFleetId
	      );
	      dispatch({
	        type: actionTypes.FETCH_HANDICAP_SUMMARY,
	        payload: response.data,
	      });
	      return { status: true, data: response.data };
	    } catch (error) {
	      console.log(error);
	      return error.response.data.message;
	    }
	  };
	};
	
	export const emptyHandicapSummary = () => {
		  return (dispatch) => {
		    dispatch({
		      type: actionTypes.EMPTY_HANDICAP_SUMMARY,
		      payload: { data: [] },
		    });
		  };
		};

    export const handicapSummaryDataByFleet = (selectedFleetData) => {
		  return (dispatch) => {
			  dispatch({
			      type: actionTypes.FLEET_HANDICAP_SUMMARY,
			      payload: { data: selectedFleetData },
			    });
			  };
			};